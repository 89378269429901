import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FaqItem from '../components/FaqItem';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Base_url, img_url } from '../api_url';
import { Helmet } from 'react-helmet';

function About() {
  const { i18n, t } = useTranslation();
  const [faqVisible, setFaqVisible] = useState(0);
  const [aboutData, setAboutData] = useState(null); // Состояние для хранения данных о "О нас"
  const [loading, setLoading] = useState(true); // Состояние для загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const [faq,setfaq] =useState([])

  useEffect(() => {
    // Выполнение GET-запроса
    axios.get(`${Base_url}${i18n.language}/about-us`)
      .then((response) => {
        if (response.data.status) {
          setAboutData(response.data.about_us); // Сохраняем данные "О нас"
        }
        setTimeout(() => {
          setLoading(false);
          // console.log('anar');
        }, 1000); // Задержка в 1 секунду
      })
      .catch((error) => {
        // console.error('Ошибка при получении данных о нас', error);
        setError('Ошибка при получении данных.');
        setLoading(false);
      });
  }, [i18n.language]);
  useEffect(() => {
    // Выполнение GET-запроса
    axios.get(`${Base_url}${i18n.language}/faq`)
      .then((response) => {
        if (response.data.status) {
          setfaq(response.data.faq.content); // Сохраняем данные "О нас"
        }
        setLoading(false); // Убираем состояние загрузки
      })
      .catch((error) => {
        // console.error('Ошибка при получении данных о нас', error);
        setError('Ошибка при получении данных.');
        setLoading(false);
      });
  }, [i18n.language]);

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
          <Helmet>
        <title>SETKAYA MMC | HAQQIMIZDA</title>
        <meta name="description" content={t('seoDescription')} />
        <meta property="og:title" content='SETKAYA MMC | HAQQIMIZDA' />
        <meta property="og:description" content={t('seoDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content='SETKAYA MMC | HAQQIMIZDA' />
        <meta name="twitter:description" content={t('seoDescription')} />
      </Helmet>
      <section className='container box about'>
        <div className='left'>
          <h2>{aboutData?.title || 'Biz kimik? Hekayəmizi kəşf edin!'}</h2>
          <p>{aboutData?.subtitle || 'Figma ipsum component variant main layer. Stroke content polygon link arrange selection layout. Blur connection slice move bold rotate figma layout plugin.'}</p>
        
        </div>
        <div className='right'>
          {aboutData?.image?.path ? (
            <img height='100%' width='100%' src={`${img_url}${aboutData.image.path}`} alt={aboutData.image.alt || 'About Us'} />
          ) : (
            <img height='100%' width='100%' src='/path/to/default/image.png' alt='Default' />
          )}
        </div>
      </section>
      <section className='container box faq'>
        <div className='faq-top'>
          <h3>{t("faq")}</h3>
         
        </div>
        <div className='faq-list'>
         
          {faq.map((item, index) => (
            <FaqItem  item={item} isVisible={faqVisible === index + 1} setFaqVisible={setFaqVisible} key={index} index={index + 1} />
          ))}
        </div>
      </section>
    </>
  );
}

export default About;
